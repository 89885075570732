.background-video-wraper {
  background-color: antiquewhite;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.background-video-wraper > video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
