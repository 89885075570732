.room-logo > img {
  height: 100%;
}
.team-name {
  padding: 0 1rem;
}
.team-name div {
  width: 100%;
  max-width: 39vw;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-green);
  font-family: NovaReg;
  font-size: 3vw;
  text-align: center;
}
.escape-time {
  color: white;
  font-family: NovaExtraB;
  font-size: 4vw;
}
.team-index {
  color: white;
  font-size: 3em;
}
