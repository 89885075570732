#chevron {
  position: relative;
  text-align: center;
  padding: 12px;
  margin-bottom: 6px;
  height: 20vh;
  width: 200px;
}
#chevron:before {
  content: "";
  position: absolute;
  top: 0;
  left: 4vw;
  height: 100%;
  width: 33%;
  border-bottom: 2px solid #da1a36;
  border-top: 2px solid #da1a36;
  transform: skew(0deg, 5.8deg);
}
#chevron:after {
  content: "";
  position: absolute;
  top: 0;
  right: 2.5vw;
  height: 100%;
  width: 31%;
  border-bottom: 2px solid #da1a36;
  border-top: 2px solid #da1a36;
  transform: skew(0deg, -5.9deg);
}
#chevron:first-of-type::before,
#chevron:first-of-type::after {
  border-top: none !important;
}

#chevron > tr > td {
  background-color: transparent !important;
}
.room__logo {
  position: relative;
  width: 28%;
  margin: auto;
  top: -3vw;
}
.diamond > .team-index {
  background-color: transparent !important;
  padding-left: 0 !important;
}
.diamond > .team-name > div {
  width: 90%;
}
.diamond > .escape-time {
  position: relative;
  left: -2%;
}
#diamond .team-index > .border-top {
  border-top: solid gray 2px;
}
