.header {
  position: relative;
  color: white;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1vw;
  padding: 5vw 0 5vw 5vw;
}
.logo {
  width: 14vw;
}
.slash {
  width: 4vw;
}
.leaderboard {
  width: 70vw;
}
