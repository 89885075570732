.NavPanel {
  display: flex;
  justify-content: flex-end;
}
.sr-only {
  padding: 1vh 1vw;
}
.btn-gameover {
  background-color: #da1a35;
}
.dropdown-menu {
  background-color: #2f2f2f;
}
.dropdown-menu * {
  color: aliceblue;
}
