#groove .room__logo {
  position: relative;
  width: 28%;
  margin: auto;
  top: 1vw;
  margin-bottom: 2vh;
}

.team-index > .border-top {
  border-top: solid gray 2px;
  width: 82%;
  position: relative;
  left: 4vw;
}
#groove {
  position: relative;
}
.groove-table {
  width: 96.6% !important;
  left: 1.3rem;
}
.groove-table tbody:nth-of-type(odd) {
  border: #827676 groove;
  border-width: 6px 2px 2px 0px;
}
.groove-table tbody:nth-of-type(even) {
  border: #827676 groove;
  border-width: 6px 0px 0px 0px;
}
.groove-table tbody:nth-of-type(1) {
  border-top: none;
}
#groove .border-top {
  display: block;
}
#groove .team-index {
  text-align: center;
  background-color: transparent;
}
.groove2 .border-top {
  display: none !important;
}
.groove2 .team-index {
  background-color: var(--gray-hover) !important;
  text-align: center;
}
