.teams-table {
  position: relative;
  width: 100%;
  /* padding: 0 5vw; */
  padding-left: 0;
}
thead {
  font-family: NovaSemiB;
  color: var(--gray-text);
  font-size: 3vw;
  text-align: left;
  position: relative;
  left: -3%;
}
.teams-table th {
  padding-bottom: 1vh;
  text-align: center;
}
thead th:nth-of-type(1) {
  padding-left: 5vw;
}
.teams-table tbody tr {
  height: 6.5vh;
}
.teams-table tr:nth-of-type(odd) > td:nth-of-type(1) {
  background-color: var(--gray-hover);
}
.teams-table td:nth-of-type(1) {
  width: 35vw;
  padding-left: 5vw;
  padding-right: 2vw;
}
.teams-table td:nth-of-type(2) {
  width: 45vw;
}
.teams-table td:nth-of-type(3) {
  width: 20vw;
}
.team-name-h {
  padding: 0 1rem;
}

.diamond {
  height: 4.5vh !important;
  position: relative;
  top: -5%;
}
.diamond-table tr:nth-of-type(odd) > td:nth-of-type(1),
.groove-table tr:nth-of-type(odd) > td:nth-of-type(1) {
  background-color: transparent;
}
