:root {
	--text-green: #a5e8c4;
	--gray-hover: #393939;
	--gray-bg: #212121;
	--gray-text: #848484;
}
@font-face {
	font-family: NovaReg;
	src: url("./assets/fonts/nova-reg.ttf");
}
@font-face {
	font-family: NovaSemiB;
	src: url("./assets/fonts/nova-semi-b.ttf");
}
@font-face {
	font-family: NovaExtraB;
	src: url("./assets/fonts/nova-extra-b.ttf");
}
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.app {
	background-color: #2f2f2f;
	height: 100vh;
	width: 100vw;
	position: relative;
	overflow: hidden;
}
