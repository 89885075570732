.app-version-wraper {
  position: fixed;
  bottom: 2vw;
  left: 2vw;
  color: var(--gray-text);
  opacity: 0.2;
  font-size: 2vw;
  font-family: NovaReg;
}
.app-location-wraper {
  position: fixed;
  bottom: 2vw;
  right: 2vw;
  color: var(--gray-text);
  opacity: 0.2;
  font-size: 2vw;
  font-family: NovaReg;
}
